<template>
  <div class="login_box">
    <div class="login_left"></div>
    <div class="login_right">
      <div class="r_main">
        <img class="login_logo" src="../../assets/img/logo.png" alt="">
        <div class="r_tptle1">{{$t('main.text171')}}</div>
        <div class="r_tptle2">{{$t('main.text171')}}</div>

        <el-form class="form_box" novalidate>

          <div class="input_p_box">
            <input v-model.trim="email" type="text" required @input="handle_input(email,1)" @focus="inp_focus(email,1)" @blur="inp_blue(1)">
            <label>
              <span class="lab" :class="email_success?'lab_color':''">{{$t('main.text173')}}</span>
              <span class="lab2">{{$t('main.text174')}}</span>
            </label>
            <template v-if="email">
              <img v-show="inp1_close" @click="email=''" class="inp_r_icon inp_r_icon1" src="../../assets/img/clear_icon.png" alt="">
              <img v-show="email_success" class="inp_r_icon" src="../../assets/img/success_icon.png" alt="">
              <img v-show="!email_success" class="inp_r_icon" src="../../assets/img/error_icon.png" alt="">
            </template>
          </div>

          <div class="input_p_box">
            <input v-model.trim="pwd" type="password" required 
            @input="handle_input(pwd,2)" @focus="inp_focus(pwd,2)" 
            @blur="inp_blue(2)"
            @keyup.enter="login_btn">
            <label>
              <span class="lab" :class="pwd_success?'lab_color':''">{{$t('main.text175')}}</span>
              <span class="lab2">{{$t('main.text176')}}</span>
            </label>
            <template v-if="pwd">
              <img v-show="inp2_close" @click="pwd=''" class="inp_r_icon inp_r_icon1" src="../../assets/img/clear_icon.png" alt="">
              <img v-show="pwd_success" class="inp_r_icon" src="../../assets/img/success_icon.png" alt="">
              <img v-show="!pwd_success" class="inp_r_icon" src="../../assets/img/error_icon.png" alt="">
            </template>
          </div>

          <div class="inp_btm_txt">
            <div class="inp_btm_txt1">
              <!-- <el-checkbox v-model="hold_login">记住登录</el-checkbox> -->
            </div>
            <div class="inp_btm_txt2" @click="$router.push({name:'forget_pwd'});">{{$t('main.text177')}}</div>
          </div>

          <div class="login_btn_box">
            <el-button type="primary" class="login_btn" @click="login_btn">{{$t('main.text161')}}</el-button>
          </div>

        </el-form>

      </div>
    </div>
  </div>
</template>

<script>
import {login} from '@/api/api'
import md5 from 'js-md5';
import * as EmailValidator from 'email-validator';
import crypto from "@/crypto/crypto";

export default {
  data() {
    return {
      email:"",
      pwd:"",
      inp1_close:false,
      inp2_close:false,
      email_success:false,
      pwd_success:false,
      hold_login:false,

    };
  },
  mounted() {
    
  },
  methods: {
    handle_input(v,type){
      if(type==1 && v){
        this.inp1_close=true;
        // 邮箱格式
        if(this.email){
          this.email_success = EmailValidator.validate(this.email);
        }else{
          this.email_success = false;
        }
      }
      else if(type==2 && v){
        this.inp2_close=true;
        // 正则表达式：字母+数字，长度6-12 可以有特殊字符
        const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9(@#$%^&+=~!?_)]{6,18}$/;
        if(this.pwd){
          this.pwd_success = regex.test(this.pwd);
        }else{
          this.pwd_success = false;
        }
      }
    },
    inp_focus(v,type){
      if(type==1 && v){
        this.inp1_close = true;
      }else if(type==2 && v){
        this.inp2_close = true;
      }
    },
    inp_blue(type){
      if(type==1){
        setTimeout(() => {
          this.inp1_close = false;
        }, 200);
      }else if(type==2){
        setTimeout(() => {
          this.inp2_close = false;
        }, 200);
      }
    },
    login_btn(){
      if(!this.email){
        return this.msg("error",this.$i18n.t('main.text162'));
      }
      if(!this.pwd){
        return this.msg("error",this.$i18n.t('main.text165'));
      }
      if(!this.email_success){
        return this.msg("error",this.$i18n.t('main.text42'));
      }
      if(!this.pwd_success){
        return this.msg("error",this.$i18n.t('main.text166'));
      }

      // sessionStorage.setItem("token", "111");
      // sessionStorage.setItem("userid", "222");  
      // this.$router.push('/');

      login({
        email:this.email,
        password:md5(this.pwd)
      }).then(res=>{
        if(res.data.code==200){
          sessionStorage.setItem("token", crypto.set(res.data.data.token));
          sessionStorage.setItem("userid", crypto.set(res.data.data.userid));
          sessionStorage.setItem("username", res.data.data.username);
          sessionStorage.setItem("role", res.data.data.role);

          this.$router.push('/');
        }else{
          this.$message.error(res.data.msg);
        }
      })

    },

  },
};
</script>

<style scoped>
.login_box{
  display: flex;
  width: 100%;
  height: 100vh;
}
.login_left{
  width: 35%;
  height: 100%;
  background: url(../../assets/img/login_left_img.png) no-repeat;
  background-size: cover;
  background-position: center;
}
.login_right{
  flex: 1;
  position: relative;
}
.r_main{
  width: 60%;
  max-width: 490px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.r_tptle1{
  color: #333;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 15px;
}
.r_tptle2{
  color: #A9A9A9;
  font-size: 16px;
  font-weight: 500; 
  margin-bottom: 100px;
}
.inp_r_icon{
  width: 18px;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translate(0,-50%);
}
.inp_r_icon1{
  right: 40px;
  cursor: pointer;
}
.lab_color{
  color: #A9A9A9;
}
.inp_btm_txt{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}
.inp_btm_txt1 >>> .el-checkbox {
  color: #838383;
}
.inp_btm_txt1 >>> .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #0A76E2;
}
.inp_btm_txt1 >>> .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0A76E2;
  border-color: #0A76E2;
}
.inp_btm_txt1 >>> .el-checkbox__inner{
  border-color: #838383;
}
.inp_btm_txt2{
  cursor: pointer;
  color: #0A76E2;
  font-size: 14px;
}
.login_btn,
.login_btn:hover
.login_btn:focus,
.login_btn:valid,
.login_btn:active,
.login_btn{
  width: 100%;
  height: 55px;
  /* line-height: 55px; */
  border-radius: 6px;
  background: #0A76E2;
  font-size: 14px;
}
</style>

<style scoped>
/* 输入框动画效果 */
.input_p_box{
  height: 55px;
  line-height: 55px;
  position: relative;
  border-radius: 3px;
  border: 1px solid #A9A9A9;
  margin-bottom: 20px;
}
.input_p_box input{
  outline: none;
  background: transparent;
  width: 100%;
  height: 50%;
  border: none;
  box-sizing: border-box;
  padding: 4px 15px;
  color: #333;
}
.input_p_box input:focus ~label,
.input_p_box input:valid ~label{
  left: 0;
  top: -20%;
  color: #0F72D5;
  font-size: 12px;
}
.input_p_box input:focus,
.input_p_box input:valid{
  position: absolute;
  left: 0;
  bottom: 3px;
}
.input_p_box:focus-within{
  border-color: #0A76E2;
}
.input_p_box .lab{
  display: none;
}
.input_p_box .lab2{
  display: block;
}
.input_p_box:focus-within .lab{
  display: block;
}
.input_p_box:focus-within .lab2{
  display: none;
}
.input_p_box input:valid ~label .lab{
  display: block;
}
.input_p_box input:valid ~label .lab2{
  display: none;
}
.input_p_box label{
  box-sizing: border-box;
  font-size: 14px;
  padding: 0 14px;
  color: #BCBCBC;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  transition: all 0.2s;
  pointer-events: none;
}
.login_btn_box{
  margin-top: 60px;
}
.login_logo{
  width: 280px;
  margin-left: -20px;
  margin-bottom: 20px;
}
</style>
